import React, { useState, useEffect } from "react";
import styles from "./customerForm.module.scss"
import { Link } from "react-router-dom";
import { padding } from "@mui/system";
import CountrySelector from "../countrySelector/countrySelector";

export default function CustomerForm(props) {
    const [country, setCountry] = useState("");
    const [emailValid, setEmailValid] = useState(false);
    // const [hovered, setHovered] = useState(false)
    const [data, setData] = useState({
        name: "",
        surname: "",
        companyName: "",
        phoneNumber: "",
        email: "",
        country: country,
        street: "",
        houseNo: "",
        flatNo: "",
        city: "",
        postalCode: ""
    })
    const emailValidator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    function setFocusToTextBox() {
        document.getElementById("name").focus();
    }

    let hovered = props.hovered;

    const handleFormChange = (e) => {

        const { id, checked, value } = e.target;
        let data_ = data;

        data_ = { ...data_, [id]: value }
        setData(data_);
        if (data_.name &&
            data_.surname &&
            data.email.match(emailValidator) &&
            data.phoneNumber &&
            data_.street &&
            data_.country &&
            data_.houseNo &&
            data_.city &&
            data_.postalCode) {
            props.onFormSubmit(true)
            props.setData(data_)
        }
        else {
            props.onFormSubmit(false)
        }
    }

    useEffect(() => {
        setData(prevValue => ({ ...prevValue, ["country"]: country }));
    }, [country])


    return (
        <>
            <div className={`${styles.customerForm} ${(props.isLoading ? styles.disabled : "")}`}>
                <h2>Customer Information:</h2>
                <form onChange={handleFormChange} disabled={props.isLoading ? true : false}>
                    <div className={styles.form}>
                        <div className={styles.twoInLine}>
                            <div className={styles.inputBox} id="name">
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="first name"
                                    id="name"
                                    value={data.name}
                                    //   onChange={handleChange}
                                    required
                                    disabled={props.isLoading ? true : false}
                                    className={` ${(hovered && !data.name ? styles.invalidInput : "")}`}
                                />
                                <span>name</span>
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="surname"
                                    id="surname"
                                    value={data.surname}
                                    //   onChange={handleChange}
                                    required
                                    disabled={props.isLoading ? true : false}
                                    className={` ${(hovered && !data.surname ? styles.invalidInput : "")}`}
                                />
                                <span>surname</span>
                            </div>
                        </div>
                        <div className={styles.twoInLine}>
                            <div className={styles.inputBox}>
                                <input
                                    type="number"
                                    placeholder=" "
                                    pattern="\d*"
                                    maxLength={15}
                                    name="phone number"
                                    id="phoneNumber"
                                    value={data.phoneNumber}
                                    required
                                    disabled={props.isLoading ? true : false}
                                    className={` ${(hovered && !data.phoneNumber ? styles.invalidInput : "")}`}

                                />
                                <span>phone number </span>
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="company name"
                                    id="companyName"
                                    value={data.companyName}
                                    //   onChange={handleChange}
                                    disabled={props.isLoading ? true : false}
                                // className={` ${(hovered && !data.companyName ? styles.invalidInput : "")}`}
                                />
                                <span>company name </span>
                            </div>
                        </div>
                        <div className={`${styles.inputBox}`}>
                            <input
                                type="text"
                                placeholder=" "
                                name="email"
                                id="email"
                                value={data.email}
                                //   onChange={handleChange}
                                required
                                disabled={props.isLoading ? true : false}
                                className={` ${(hovered && !data.email.match(emailValidator) ? styles.invalidInput : "")}`}
                            />
                            <span>email</span>
                        </div>
                        <h2 style={{ margin: '0px' }}>Address:</h2>

                        <div className={`${styles.countrySelect} ${(props.isLoading ? styles.disabled : "")}`} >
                            <CountrySelector selectedValue={setCountry} />
                        </div>
                        <div className={styles.inputBox}>
                            <input
                                type="text"
                                placeholder=" "
                                name="street_name"
                                id="street"
                                value={data.street}
                                //   onChange={handleChange}
                                required
                                disabled={props.isLoading ? true : false}
                                className={` ${(hovered && !data.street ? styles.invalidInput : "")}`}
                            />
                            <span>Street name</span>
                        </div>
                        <div className={styles.twoInLine}>

                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="house_number"
                                    id="houseNo"
                                    value={data.houseNo}
                                    //   onChange={handleChange}
                                    required
                                    disabled={props.isLoading ? true : false}
                                    className={` ${(hovered && !data.houseNo ? styles.invalidInput : "")}`}
                                />
                                <span>House number</span>
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="flat_number"
                                    id="flatNo"
                                    value={data.flatNo}
                                    //   onChange={handleChange}
                                    disabled={props.isLoading ? true : false}
                                />
                                <span>flat number</span>
                            </div>
                        </div>
                        <div className={styles.twoInLine}>

                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="city"
                                    id="city"
                                    value={data.city}
                                    //   onChange={handleChange}
                                    required
                                    disabled={props.isLoading ? true : false}
                                    className={` ${(hovered && !data.city ? styles.invalidInput : "")}`}
                                />
                                <span>City</span>
                            </div>
                            <div className={styles.inputBox}>
                                <input
                                    type="text"
                                    placeholder=" "
                                    name="postal_code"
                                    id="postalCode"
                                    value={data.postalCode}
                                    //   onChange={handleChange}
                                    required
                                    disabled={props.isLoading ? true : false}
                                    className={` ${(hovered && !data.postalCode ? styles.invalidInput : "")}`}
                                />
                                <span>Postal code</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}
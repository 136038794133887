import React, { useState, useEffect } from "react";
import ProductsList from "./productsList";


export default function ProductsInMainPage(props) {

    return (
        <>
            <ProductsList title="Available paintings:" allowedToLoadMore="true" limit="30" />
            <ProductsList disableLoader={true} title="Sold paintings:" soldPaintings="true" limit="30" />
        </>
    )
}
import React, { useState, useEffect } from "react";
import styles from "./product.module.scss"
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Modal from "../modal/modal";
import { Link } from "react-router-dom";

export default function Product(props) {

    // let products = document.querySelectorAll(`.${styles.card}`)
    // function scrollTrigger() {
    //     products.forEach(product => {
    //         if (product.offsetTop < window.scrollY) {
    //             product.classList.add(`${styles.active}`)
    //         }
    //         else {
    //             product.classList.remove(`${styles.active}`)
    //         }
    //     })
    // }
    // window.addEventListener('scroll', scrollTrigger)


    const formatedPrice = (number) => {
        return (number.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        )
    }

    return (
        <>
            <Link to={`/paintings/${props.product.id}`} style={{ textDecoration: 'none' }}>
                <div className={`${styles.container}`}>

                    <div className={`${styles.card}`} key={props.product.title}>
                        <div className={styles.imgBox}>
                            <img src={props.product.img_location ? props.product.img_location[0] : ""} alt="" />
                        </div>

                        <div className={`${styles.content}`}>
                            <div className={styles.label}>{props.product.label}</div>
                            <div className={styles.price}>{`${props.product.sold ? "Sold" : formatedPrice(parseFloat(props.product.price)) + " €"} `}</div>
                            {/* {props.product.original
                                ? <div className={styles.originalLabel}>Original</div>
                                : <div className={styles.originalLabel}>Orignal</div>} */}

                        </div>
                    </div>
                </div>
            </Link>
        </>
    )
}
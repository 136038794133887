import React, { useState, useEffect } from "react";
import styles from "./cartCheckout.module.scss"
import { Link, Navigate } from "react-router-dom";
import { padding } from "@mui/system";
import CountrySelector from "./countrySelector/countrySelector";
import CustomerForm from "./customerForm/customerForm";
import axios from "axios";
import MD5 from "crypto-js/md5";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import CheckoutForm from "./stripe/StripeCheckoutForm";
import CreditCardIcon from '@mui/icons-material/CreditCard';

export default function CartCheckout(props) {

    const [submittedForm, setSubmittedForm] = useState(false);
    const [customerData, setCustomerData] = useState({})
    const [hovered, setHovered] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [stripe, setStripe] = useState(false);

    const initiatePayserPayment = async () => {
        setHovered(true);
        setIsLoading(true);
        if (submittedForm && localStorage.getItem("cart")) {
            let productsFromCart = [];
            let products = JSON.parse(localStorage.getItem("cart")).products
            if (products && products.length > 0) {
                for (const child of products) {
                    productsFromCart = [...productsFromCart, child.id]
                }
            }

            let order = {
                productIds: productsFromCart,
                customer: customerData

            }

            const response = await fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/paysera", {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(order)
            })
            const data = await response.text();
            if (data) {

                window.open(data, '_self')
            }
        }
        else {
            alert("Please submit the form")
            setIsLoading(false);
            window.scrollTo(0, 0);
        }
    }

    const initiateStripePayment = async () => {
        setHovered(true);
        setIsLoading(true);
        if (submittedForm && localStorage.getItem("cart")) {
            let products = JSON.parse(localStorage.getItem("cart")).products
            if (products && products.length > 0) {
                await getClientSecret();
                setStripe(true)

            }
            setIsLoading(false);
        }
        else {
            alert("Please submit the form")
            setIsLoading(false);
            window.scrollTo(0, 0);
        }
    }

    const [clientSecret, setClientSecret] = useState("");

    const wait = (delay) => (new Promise((resolve) => setTimeout(resolve, delay)));

    const getClientSecret = async (tries = 3) => {
        let productsFromCart = [];
        let cart = JSON.parse(localStorage.getItem("cart"))
        if (cart.products) {
            cart.products.forEach(element => {
                productsFromCart.push(element.id)
            });
        }

        let order = {
            productIds: productsFromCart,
            customer: customerData
        }
        await fetch('https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/stripe/create-intent', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(order)
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json()
                setClientSecret(data.clientSecret);
            }
            else if (response.status === 404) {
                if (tries < 1) {
                    console.log("404")
                }
                else {
                    const delay = Math.floor(Math.random() * 1000);
                    wait(delay).then(() => getClientSecret(tries - 1));
                }
            }
        }).catch((error) => {
            if (tries < 1) {
                throw error;
            }
            else {
                console.log("retrying")
                const delay = Math.floor(Math.random() * 1000);
                wait(delay).then(() => getClientSecret(tries - 1));
            }
        })

    };



    return (
        <>
            {isLoading ? <div className={styles.loader}></div> : ""}
            {stripe ? <CheckoutForm customerData={customerData} clientSecret={clientSecret} /> :
                <div className={`${styles.pageLayout}`}>
                    <CustomerForm onFormSubmit={setSubmittedForm} setData={setCustomerData} hovered={hovered} isLoading={isLoading} />
                    <div className={`${styles.paymentOptions} ${(isLoading ? styles.disabled : "")}`}>
                        <h2>Please seect payment method:</h2>
                        {/* <div className={styles.paysera}
                            onClick={() => initiatePayserPayment()}>
                            <img src="/paysera-logo.svg" />
                        </div> */}
                        <div className={styles.card} onClick={() => initiateStripePayment()}>
                            <CreditCardIcon fontSize="large" />
                            <img src="/applepay-logo.svg" />
                            <img src="/google-pay.svg" />
                        </div>
                        {/* <div className={styles.applePay}>
                            <img src="/applepay-logo.svg" />
                        </div> */}
                    </div>
                </div >
            }
        </>
    )
}
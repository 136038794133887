import React, { useState, useEffect } from "react";
import { loadStripe } from '@stripe/stripe-js';
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import styles from "./stripeCheckoutForm.module.scss"

export default function CheckoutForm(props) {
    const clientSecret = props.clientSecret;
    const [data, setData] = useState({
        name: "",
        surname: "",
        companyName: "",
        email: "r4p0l4s@gmai.com",
        country: "LT",
        street: "",
        houseNo: "",
        flatNo: "",
        city: "",
        postalCode: ""
    })
    const stripePromise = loadStripe('pk_live_51OgYktIlHuuDfhlAG3Z32Q7ACBe1x65zlANZZvgmngJJlEBnNIsKbnaMmKscnaJjSnU6RjkxwL5o97MvEcR4h5KZ005sJTMg5B');

    // useEffect(() => {
    //     let productsFromCart = [];
    //     let cart = JSON.parse(localStorage.getItem("cart"))
    //     if (cart.products) {
    //         cart.products.forEach(element => {
    //             productsFromCart.push(element.id)
    //         });
    //     }

    //     let order = {
    //         productIds: productsFromCart,
    //         customer: props.customerData
    //     }
    //     // Create a Checkout Session as soon as the page loads
    //     fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/stripe/create-intent", {
    //         method: "POST",
    //         body: JSON.stringify(order),
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json'
    //         },
    //     })
    //         .then((res) => res.json())
    //         .then((data) => setClientSecret(data.clientSecret));
    // }, []);

    return (
        <div id="checkout" className={styles.pageLayout}>
            {clientSecret && (
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </div>
    )
}
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import styles from "./countrySelector.module.scss"
import { Link } from "react-router-dom";
import { padding } from "@mui/system";
import { AnimatePresence, motion } from "framer-motion";
import { COUNTRIES } from "./countries";





export default function CountrySelector({
    id,
    open,
    disabled = false,
    onToggle,
    onChange,
    selectedValue,
}) {
    const [openDropdown, setOpenDropdown] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("")


    const handleOpen = () => {
        setOpenDropdown(!openDropdown);
    };
    function useOutsideAlerter(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpenDropdown(false)
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const handleSelect = (props) => {
        setOpenDropdown(!openDropdown)
        setSelectedCountry(props.title.toUpperCase())
        selectedValue(props.title.toUpperCase())
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const handleChange = (props) => {
        setOpenDropdown(true)
        setSelectedCountry(props.target.value.toUpperCase())
    }


    return (
        <>
            <div>
                <div onClick={handleOpen} className={styles.dropdown} ref={wrapperRef}>
                    <input
                        type="text"
                        placeholder=" "
                        name="country"
                        id="country"
                        value={selectedCountry}
                        onChange={handleChange}
                        required
                    />
                    <span className={`${styles.dropdownLabel} ${selectedCountry === "" ? "" : styles.countrySelected}`}>
                        Country</span>
                    {openDropdown ? <div className={`${styles.dropdownMenu} `}>
                        {COUNTRIES.map((country) => (

                            country.title.toLowerCase().includes(selectedCountry.toLowerCase()) ?
                                <div className={styles.option} value={country.value} onClick={(x) =>
                                    (handleSelect(country))}>{country.title}</div>
                                :
                                ""
                        ))}</div>
                        : ""}</div>
            </div>

        </>

    );
}
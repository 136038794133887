import React, { useEffect, useState } from "react";
import styles from "./legal.module.scss";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router";


export default function LegalDocument(props) {
    const { legalDocument } = useParams()
    const [markdown, setMarkdown] = useState("");
    const filePath = require(`./documents/${legalDocument}.md`);

    useEffect(() => {
        fetch(filePath)
            .then((res) => res.text())
            .then((text) => setMarkdown(text));
    }, [legalDocument]);

    return (<div className={styles.pageLayout}>
        <ReactMarkdown children={markdown} />
    </div>);
}

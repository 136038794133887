import React, { useState, useEffect } from "react";
import styles from "./productDescription.module.scss"

import { Navigate, useNavigate, useParams } from "react-router-dom";
import ProductsList from "../productsList/productsList";
import Modal from "../modal/modal";
import ImgModal from "../modal/imgModal";

export default function ProductDescription(props) {

    const [isLoading, setIsLoading] = useState(false);
    const { paintingId } = useParams()
    const [modal, setModal] = useState(false);
    const [thisPainting, setThisPainting] = useState({})

    // const thisPainting = paintingsData.find(product => product.label === paintingName)

    // const thisProduct = productsData.find(prod => prod.id === productId)

    useEffect(() => {
        const isModalActive = () => {
            if (modal) {

            }
            else {
                document.body.style.overflow = 'unset';
            }
        }
        isModalActive()
    }, [modal]);

    const hasProduct = (obj, targetId) => {
        if (obj.id === targetId) {
            return true;
        }

        if (obj.products && obj.products.length > 0) {
            for (const child of obj.products) {
                if (hasProduct(child, targetId)) {
                    return true;
                }
            }
        }
        return false;
    }

    const updateCart = async () => {
        let productsInCart = JSON.parse(localStorage.getItem("cart"))
        if (productsInCart === null) {

            localStorage.setItem("cart", JSON.stringify(
                {
                    totalProducts: 1,
                    totalPrice: thisPainting.price,
                    products: [
                        {
                            id: thisPainting.id,
                            label: thisPainting.label,
                            price: thisPainting.price,
                            imgLocation: thisPainting.img_location[0]
                        }
                    ]
                }
            ))
        }
        else {
            localStorage.setItem("cart", JSON.stringify({
                ...productsInCart, totalProducts: productsInCart.totalProducts += 1,
                totalPrice: productsInCart.totalPrice += thisPainting.price,
                products: [
                    ...productsInCart.products,
                    {
                        id: thisPainting.id,
                        label: thisPainting.label,
                        price: thisPainting.price,
                        imgLocation: thisPainting.img_location[0]
                    }
                ]
            }))
        }
        await props.updateCartCount(1)
    }

    const getProduct = async () => {
        setIsLoading(true);

        const response = await fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/products/" + paintingId, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        const data = await response.json();
        setThisPainting(data)

        setIsLoading(false);
    }

    useEffect(() => {
        let isCancelled = false;
        if (!isCancelled) {
            getProduct();
        }

        return () => {
            isCancelled = true;
        }

    }, [paintingId])

    const imgs = document.querySelectorAll('.gallery img');
    const fullPage = document.querySelector('#fullpage');

    imgs.forEach(img => {
        img.addEventListener('click', function () {
            fullPage.style.backgroundImage = 'url(' + img.src + ')';
            fullPage.style.display = 'block';
        });
    });

    const productInCart = localStorage.getItem("cart") ? hasProduct(JSON.parse(localStorage.getItem("cart")), thisPainting.id) : false;

    const navigate = useNavigate();

    return (
        <>

            {thisPainting === undefined ? <Navigate to="/" /> :
                (isLoading ? <div className={styles.loader}> </div> :
                    <div className={`${styles.pageLayout} ${modal ? styles.noScroll : ""} `}>
                        <div id="fullpage" onclick="this.style.display='none';"></div>
                        <div className={`${styles.mainPage} gallery`}>
                            <ImgModal open={false} painting={thisPainting} />
                            {/* <img className={`${styles.productImg}`} alt="product-img" src={thisPainting.imgLocation}
                                onClick={() => { activateImgPreview() }}></img> */}
                            <div className={styles.infoBlock}>
                                <div className={styles.productName}>{thisPainting.label}</div>
                                <div className={styles.productSize}>{thisPainting.size}</div>
                                <div className={styles.productSize}>{thisPainting.technique}</div>
                                <div className={styles.productPrice}>{thisPainting.price} €</div>
                                {thisPainting.sold
                                    ? ""
                                    :
                                    <div className={styles.a} > {<>
                                        {productInCart
                                            ? <Modal removeScroll={false} iconVisible={false} buttonName="Already in cart" painting={thisPainting} open={false} function={() => navigate("/cart")} />
                                            : <Modal removeScroll={true} iconVisible={true} buttonName="Add to cart " painting={thisPainting} open={false} function={updateCart} />
                                        }
                                    </>
                                    }</div>
                                }
                            </div>
                        </div>
                        <ProductsList limit="4" title="Available paintings:" />
                    </div>
                )}
        </>
    )
}
import { useState } from "react";
import styles from "./imgModal.module.scss";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";


export default function ImgModal(props) {
    const [modal, setModal] = useState(props.open);
    const [selectedImage, setSelectedImage] = useState(0)

    const thisPainting = props.painting;

    const toggleModal = async () => {
        if (!modal) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'unset';
        }

        setModal(!modal);

    };

    return (
        <>
            <div className={styles.pageLayout}>
                <div className={styles.imgContainer} onClick={() => { toggleModal() }}>
                    {thisPainting.img_location ?
                        <img className={styles.defaultImage} alt="product-img" src={thisPainting.img_location[selectedImage]} />
                        : <></>}
                    {/* <img className={styles.defaultImage} alt="product-img" src={thisPainting.img_location ? thisPainting.img_location[0] : thisPainting.img_location} /> */}
                </div>
                <div className={styles.imgSelector}>
                    {thisPainting.img_location ?
                        thisPainting.img_location.length > 1 ?
                            thisPainting.img_location.map((element, index) => (
                                <>
                                    <div className={`${styles.selectionButton} ${selectedImage === index ? styles.selectedSelectpr : ""}`} onClick={() => setSelectedImage(index)}><img className={styles.selectorImage} alt="product-img" src={element} /></div>
                                </>
                            ))
                            : <></>
                        :
                        <></>}
                </div>
            </div>
            {modal && (
                <div className={styles.modal}>
                    <div className={styles.overlay} onClick={toggleModal}></div>
                    <div className={styles.modalContent}>
                        <ClearRoundedIcon
                            sx={{ fontSize: { xs: 22, sm: 24, md: 28, lg: 42 }, fontWeight: 500 }}
                            color="error"
                            onClick={toggleModal}
                            className={styles.closeModal}
                        />
                        <div className={styles.content}>
                            <img className={styles.productImg} alt="product-img" src={thisPainting?.img_location[selectedImage]} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

import React, { useState } from "react";
import styles from "./itemsInCart.module.scss"

import { useNavigate } from "react-router-dom";
import RemoveFromCartModal from "../modal/removeFromCartModal";

export default function ItemsInCart(props) {

    const [modal, setModal] = useState(false);

    const navigate = useNavigate(); // From react-router v6

    const activateModal = () => {
        setModal(true)
    }

    const removeItemFromCart = () => {
        let productsInCart = JSON.parse(localStorage.getItem("cart"))

        let removeIndex = productsInCart.products.map(item => item.id).indexOf(props.painting.id)

        ~removeIndex && productsInCart.products.splice(removeIndex, 1)
        if (~removeIndex) {
            if (productsInCart.totalProducts === 1) {
                localStorage.removeItem("cart")
                props.updateCart(!props.cart)
                props.updateCartCount(0)
            }
            else {
                productsInCart.totalProducts -= 1;
                productsInCart.totalPrice -= props.painting.price
                props.updateCart(!props.cart)
                localStorage.setItem("cart", JSON.stringify(productsInCart))
                props.updateCartCount(1)
            }
        }
    }

    const formatedPrice = (number) => {
        return (number.toLocaleString(navigator.language, { minimumFractionDigits: 0 })
        )
    }
    return (
        <>
            {/* <Link to={`/paintings/${props.label}`} style={{ textDecoration: 'none' }}> */}
            <div className={`${styles.container}`} >

                <div className={`${styles.card}`} key={props.painting.title} onClick={() => { navigate(`/paintings/${props.painting.id}`) }}>
                    <div className={styles.imgBox}>
                        <img src={props.painting.img_location ? props.painting.img_location[0] : ""} alt="" />
                    </div>
                    <div className={`${styles.content}`}>
                        <div className={styles.labelContainer} >
                            <div className={styles.label} >{props.painting.label}</div>
                        </div>
                        <div className={styles.price}>{formatedPrice(parseFloat(props.painting.price))} €</div>
                    </div>
                </div>
                <div className={styles.removeItem}>
                    <div
                        onClick={() => { activateModal() }}
                    />
                    <RemoveFromCartModal painting={props.painting} open={modal} function={removeItemFromCart} />
                </div>
            </div>
            {/* </Link> */}
        </>
    )
}

import { useState } from "react";
import styles from "./Modal.module.scss";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { Navigate, redirect, useParams, NavLink } from "react-router-dom";


export default function RemoveFromCartModal(props) {
  const [modal, setModal] = useState(props.open);

  const { paintingName } = useParams()
  const thisPainting = props.painting;


  const toggleModal = async () => {
    if (!modal) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'unset';
    }

    setModal(!modal);

  };

  return (
    <>
      <div className={styles.modalButtonRemoveFromCart} onClick={() => { toggleModal() }}>
        <ClearRoundedIcon
          color="error"
        />
        Remove
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.overlay} onClick={toggleModal}></div>
          <div className={styles.modalContent}>
            <ClearRoundedIcon
              color="error"
              onClick={toggleModal}
              className={styles.closeModal}
            />
            <h2 className={styles.title}>{thisPainting.label}</h2>
            <div className={styles.content}>
              <img className={styles.productImg} src={thisPainting.imgLocation} />
              <span className={styles.bold}> Are you sure ?</span> {props.textEnd}
            </div>
            <div className={styles.actionButtons}>
              <div
                className={styles.closeButton}
                onClick={
                  toggleModal
                }
              >
                Keep in cart
              </div>
              <NavLink to="/cart" style={{ textDecoration: 'none' }}>
                <div className={styles.removeItemButton} onClick={() => { toggleModal(); props.function() }}>
                  Remove!
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import React, { useState, useEffect } from "react";
import styles from "./about.module.scss"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Article from "./articles/article";
import exhibitions from "./exhibitions.json"

export default function About(props) {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add(styles.show)
            } else {
                entry.target.classList.remove(styles.show)
            }
        })
    })

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((element) => observer.observe(element))

    return (
        <>
            <div className={styles.aboutPage} id="about">
                <img className={styles.background} alt="about background" src="./D95A2031.jpg">
                </img>
                <div className={styles.aboutMe}>
                    <h3 className={styles.intro}>M A R I U S <br /> K A V O L I S</h3>
                    <p className={styles.info}>Original paintings</p>
                </div>
            </div>
            <div className={styles.aboutInfoPage}>
                <div className={`${styles.portrait} hidden`}>
                    <img className={styles.portraitPhoto} alt="kavolis-portrait" src="../marius-kavolis-portrait.jpg" />
                </div>
                <div className={styles.biography}>
                    <div className={styles.story}>

                        Marius Kavolis (born 1987) Lithuanian contemporary artist. Young artist's creations - expressive, captivating abstraction, fixing, according to M. Kavolis, moments of energy and good mood. Marius Kavolis is inspired by artists such as Jackson Pollock, Albert Oehlen and Sam Francis. In terms of coloring the influence was made by Jean Michel Basquiat, painting techniques - Gerhard Richter.                    <br></br>
                    </div>
                    <br></br>
                    {/* <b>Exibitions:</b>
                    <ul>
                        <li>
                            2020 Personal exhibition “Evolution “Ramybes gallery Palanga;
                        </li>
                        <li>
                            2019. Personal exhibition„Therapy of Spring“, Palangos Resort museum Lietuva;
                        </li>
                        <li>
                            2018 Now .Spring breez .the government place Lithuanian;
                        </li>
                        <li>
                            2018 Now SOLO exhibition, Elysium gallery Spain, Llore del mar;
                        </li>
                        <li>
                            2018 Now SOLO exhibition ,Kaoni Gallery . Barcelona .Spain;
                        </li>
                        <li>
                            2017 - 2018. Personal exhibition ‘Big Explosion’, Clear gallery, Moscow, Russia;
                        </li>
                        <li>
                            2017. Summer 2017 collection at business centre 3BURĖS, Vilnius, Lithuania;
                        </li>
                        <li>
                            2016. Happy Wheels, M Art gallery, Klaipeda, Lithuania;
                        </li>
                        <li>
                            2016. Autumn SOLO, Music gallery, Vilnius, Lithuania;
                        </li>
                        <li>
                            2016. SOLO exhibition Muasir Incesenet Merkezi, Baku, Azerbaijan;
                        </li>
                        <li>
                            2016. Personal exhibition’12 ½’, Nizhny Novgorod State Art Mueseum, Russia;
                        </li>
                        <li>
                            2016. Personal exhibition Colors of the Summer, M Art Gallery, Klaipėda, Lithuania;
                        </li>
                        <li>
                            2016. Joint exhibition Distance, Gallery A3, Old Arbat, Moscow; Russia;
                        </li>
                        <li>
                            2016. Affordable Art Fair, Milan, Italy;
                        </li>
                        <li>
                            2016. Exhibition in Vilnius city municipality, Lithuania;
                        </li>
                        <li>
                            2015. Cosmoscow art fair, Russia;
                        </li>
                        <li>
                            2015. International art festival ‘Open Sea’, Palanga, Lithuania;
                        </li>
                        <li>
                            2015. International contemporary art fair ‘Art Vilnius’, Lithuania;
                        </li>
                        <li>
                            2015. Personal exhibition, Embassy of the Republic of Lithuania, Moscow, Russia;
                        </li>
                        <li>
                            2015. Affordable Art Fair, Milan, Italy;
                        </li>
                        <li>
                            2014. Personal exhibition ’12 ½’, Elysium Gallery, Vilnius Lithuania;
                        </li>
                    </ul> */}
                </div>
            </div>

            <div className={styles.exhibitionsLayout}>
                <h2 className={styles.title}>Exhibitions:</h2>

                {
                    exhibitions.map((exhibition) => (

                        <div className={styles.exhibitionContainer}>
                            <div className={styles.exhibitionInfoContainer}>
                                <div className={styles.exhibitionYear}>{exhibition.year}</div>
                                <div className={styles.exhibitionTitle}>{exhibition.title}</div>

                                <div className={styles.exhibitionLocation}>{exhibition.location}</div>
                            </div>
                        </div>
                    ))
                }
            </div>
            <h2 className={styles.title}>Marius Kavolis articles:</h2>
            <div className={styles.articlesPage} >
                {/* <div className={styles.title}>Articles:</div> */}
                <Article articleImg="../zemutinio-naugardo-valstybiniame-muziejuje.jpg" articleTitle="Marius Kavolis: colors, no paraules" onClick="https://www.nuvol.com/art/marius-kavolis-colors-no-paraules-53798" />
                <Article articleImg="../KAONI_SOLO.jpg" articleTitle="EXPOSICIÓN SOLO DE MARIUS KAVOLIS EN KAONI GALLERY" onClick="https://aliciagarcia.com/exposicion-solo-de-marius-kavolis-en-kaoni-gallery/" />
                <Article articleImg="../meno-reikalas.jpg" articleTitle="„Meno Reikalas“ negimė iš reikalo." onClick="https://www.15min.lt/media-pasakojimai/meno-reikalas-11-91#Intro%20full" />
                <Article articleImg="../palangos-kurorto-muziejuje-paroda.jpg" articleTitle="Palangos kurorto muziejuje atidaroma dailininko Mariaus Kavolio paroda" onClick="https://ve.lt/naujienos/kultura/parodos/palangos-kurorto-muziejuje-atidaroma-dailininko-mariaus-kavolio-paroda-1687202" />
                <Article articleImg="../exhibition-in-baku.jpg" articleTitle="Lithuanian artist presents solo exhibition in Baku" onClick="https://www.azernews.az/culture/104011.html" />
                <Article articleImg="../jura-duoda-savo-energijos.jpg" articleTitle="Iš Palangos kilęs pasaulyje žinomas tapytojas: jūra duoda savo energijos" onClick="https://www.delfi.lt/miestai/palanga/is-palangos-kiles-pasaulyje-zinomas-tapytojas-jura-duoda-savo-energijos-80160583" />
                <Article articleImg="../zemutinio-naugardo-valstybiniame-muziejuje-eksponuojami-mariaus-kavolio-darbai.jpg" articleTitle="Žemutinio Naugardo valstybiniame muziejuje buvo pristatytas šiuolaikinis Lietuvos dailininkų menas" onClick="https://www.15min.lt/galerija/zemutinio-naugardo-valstybiniame-muziejuje-buvo-pristatytas-siuolaikinis-lietuvos-dailininku-menas-102753#_" />

            </div>
        </>
    )
} 
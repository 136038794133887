import React, { useState, useEffect } from "react";
import styles from "./article.module.scss"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

export default function Article(props) {

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add(styles.show)
            } else {
                entry.target.classList.remove(styles.show)
            }
        })
    })

    const hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((element) => observer.observe(element))
    return (
        <>
            <div className={` ${styles.pageLayout}  ${styles.hidden}   hidden`} onClick={() => window.open(props.onClick ? props.onClick : "kavolis.com", "_blank")}>
                <div className={styles.articlePhoto}>
                    <img className={styles.articlePhoto} src={props.articleImg} />
                </div>
                <div className={styles.articleTitle}>{props.articleTitle}</div>
            </div>
        </>
    )
} 
import React, { useState, useEffect } from "react";
import styles from "./footer.module.scss"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from "react-scroll";
import { width } from "@mui/system";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export default function Footer(props) {

    const path = useLocation().pathname;
    const location = path.split("/")[1];
    const navigate = useNavigate()

    function sendEmail() {
        window.location = "mailto:marius.kavolis@gmail.com";
    }

    return (
        <>
            <div className={styles.container}>
                <div className={styles.pageLayout}>
                    <div className={styles.navParent}>
                        {location === "" ?
                            <div className={styles.navigation}>
                                <Link className={styles.navigationItem} to={"about"} spy={true} smooth={true} offset={-100} duration={500}>
                                    <span>About</span>
                                </Link>
                                <Link className={styles.navigationItem} to={"paintingsList"} spy={true} smooth={true} offset={-110} duration={500}>
                                    <span>Shop</span>
                                </Link>
                            </div>
                            :
                            <div className={styles.navigation}>
                                <NavLink to={"/"} style={{ textDecoration: 'none' }} className={styles.navigationItem} >
                                    <span>About</span>
                                </NavLink>
                                <NavLink to={"/"} style={{ textDecoration: 'none' }} className={styles.navigationItem}>
                                    <span>Shop</span>
                                </NavLink>
                            </div>
                        }
                    </div>
                    <div className={`${styles.navigation} ${styles.legal}`}>
                        <NavLink to={"/legal/shipping-policy"} style={{ textDecoration: 'none' }} className={styles.navigationItem} >
                            <span>Shipping Policy</span>
                        </NavLink>
                        <NavLink to={"/legal/returns-policy"} style={{ textDecoration: 'none' }} className={styles.navigationItem} >
                            <span>Returns Policy</span>
                        </NavLink>
                        <NavLink to={"/legal/privacy-policy"} style={{ textDecoration: 'none' }} className={styles.navigationItem} >
                            <span>Privacy Policy</span>
                        </NavLink>
                        <NavLink to={"/legal/terms-of-service"} style={{ textDecoration: 'none' }} className={styles.navigationItem} >
                            <span>Terms of Service</span>
                        </NavLink>
                    </div>
                    <div className={styles.socMedia}>
                        <img
                            src="../linkedin_logo.svg"
                            alt="LinkedIn account"
                            onClick={() =>
                                window.open("https://www.linkedin.com/in/marius-kavolis-0072b391")
                            }
                            className={styles.socialMedia}
                            id={styles.linkedIn}
                        />
                        <img
                            src="../instagram.svg"
                            alt="LinkedIn account"
                            onClick={() => window.open("https://www.instagram.com/mkavolis/")}
                            className={styles.socialMedia}
                            id={styles.instagram}
                        />
                        <img
                            src="../facebook-logo.svg"
                            alt="Facebook account"
                            onClick={() => window.open("https://www.facebook.com/profile.php?id=100062968043245")}
                            className={styles.socialMedia}
                            id={styles.instagram}
                        />
                        <img
                            src="../TikTok-logo.svg"
                            alt="TikTok account"
                            onClick={() => window.open("https://www.tiktok.com/@mkavolis")}
                            className={styles.socialMedia}
                            id={styles.instagram}
                        />
                        <img
                            src="../nft-token.svg"
                            alt="Rarible account"
                            onClick={() => window.open("https://rarible.com/user/0x4740c93579eae189dcd3a2172c16cef0268c6fef/owned")}
                            className={styles.socialMedia}
                            id={styles.instagram}
                        />
                    </div>
                    <div className={styles.contacts} onClick={() => sendEmail()}> <MailOutlineIcon /> marius.kavolis@gmail.com</div>
                </div>
            </div>
        </>
    )
}
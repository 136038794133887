import { useState } from "react";
import styles from "./Modal.module.scss";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { NavLink } from "react-router-dom";


export default function Modal(props) {
  const [modal, setModal] = useState(props.open);

  const thisPainting = props.painting;


  const toggleModal = async () => {
    if (!modal && props.removeScroll) {
      document.body.style.overflow = 'hidden';
    }
    else {
      document.body.style.overflow = 'unset';
      if (props.closeModal) {
      }
    }

    setModal(!modal);

  };

  return (
    <>
      <div className={styles.modalButton} onClick={() => { toggleModal(); props.function() }}>
        {props.buttonName}{props.iconVisible ? <AddShoppingCartIcon /> : ""}
      </div>
      {modal && (
        <div className={styles.modal}>
          <div className={styles.overlay} onClick={toggleModal}></div>
          <div className={styles.modalContent}>
            <ClearRoundedIcon
              color="error"
              onClick={() => { toggleModal() }}
              className={styles.closeModal}
            />
            <h2 className={styles.title}>{thisPainting.label}</h2>
            <div className={styles.content}>
              <img className={styles.productImg} src={thisPainting?.img_location[0]} alt="product" />
              <span className={styles.bold}> Has been successfully added to cart!</span> {props.textEnd}
            </div>
            <div className={styles.actionButtons}>
              <div
                className={styles.confirmButton}
                onClick={
                  toggleModal
                }
              >
                Continue browsing
              </div>
              <NavLink to="/cart" style={{ textDecoration: 'none' }}>
                <div className={styles.closeButton} onClick={toggleModal}>
                  Go to checkout
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

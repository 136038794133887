import React, { useState, useEffect } from "react";
import styles from "./cart.module.scss"
import { NavLink } from "react-router-dom";
import ProductsList from "../productsList/productsList";
import ItemsInCart from "../itemsInCart/itemsInCart";

export default function Cart(props) {

    const [updatedCart, setUpdatedCart] = useState(false)
    const [totalAmount, setTotalAmount] = useState(localStorage.getItem("cart")
        ? (JSON.parse(localStorage.getItem("cart")).totalPrice)
        : 0)
    const [paintingsData, setPaintingsData] = useState(localStorage.getItem("cart")
        ? (JSON.parse(localStorage.getItem("cart")).products)
        : [])

    useEffect(() => {
        updateCart()
    }, [])

    const updateCart = async () => {
        const productsInCart = []
        let cart = JSON.parse(localStorage.getItem("cart"))
        if (cart && cart.products) {
            cart.products.forEach(element => {
                productsInCart.push(element.id)
            });
        }
        let request = {
            ids: productsInCart
        }
        const response = await fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/products/cart", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(request)
        })
        const data = await response.text();
        localStorage.setItem("cart", data)
        props.updateCartCount(JSON.parse(data).totalProducts)
        setTotalAmount(JSON.parse(data).totalPrice)
        setPaintingsData(JSON.parse(data).products);
    }

    useEffect(() => {
        setPaintingsData(localStorage.getItem("cart")
            ? (JSON.parse(localStorage.getItem("cart")).products)
            : [])
    }, [updatedCart])



    return (
        <>
            <div className={styles.pageLayout}>
                {paintingsData.length > 0 ?
                    <div className={styles.mainView}>
                        <div className={styles.itemsInCart}>
                            <h2 >Your selected products</h2>

                            {paintingsData.map((painting) => {
                                return (<>
                                    <ItemsInCart painting={painting} cart={updatedCart} updateCart={setUpdatedCart} updateCartCount={props.updateCartCount} layout="cart" /> </>)

                            }
                            )}
                            <div className={styles.totalContainer}>
                                <div className={styles.total}>Total:  {totalAmount} €</div>
                            </div>
                        </div>
                        <div className={styles.shippingContainer}>
                            <h2>Cart totals</h2>
                            <div className={styles.cartInfo}>
                                <div className={styles.label}>Subtotal:</div>
                                <div className={styles.info}>{totalAmount} €</div>
                            </div>
                            <div className={styles.cartInfo}>
                                <div className={styles.label}>Shipping:</div>
                                <div className={styles.info}>Free shipping within Lithuania</div>
                            </div>
                            <div className={styles.cartInfo}>
                                <div className={styles.label}>Total:</div>
                                <div className={styles.info}>{totalAmount} €</div>
                            </div>
                            <NavLink to={"/checkout"} style={{ textDecoration: 'none' }}>
                                <div className={styles.buttonContainer}>
                                    <div className={styles.nextButton}>{`Proceed to checkout >`}</div>
                                </div>
                            </NavLink>

                        </div>
                    </div>
                    : <>

                        <h2>There are no items in cart</h2>

                    </>}
                <ProductsList title="Available paintings:" limit="4" />
            </div>
        </>
    )
}
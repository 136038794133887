import React, { useEffect, useState } from "react";
import styles from "./loader.module.scss";

export default function Loader(props) {
  const [isLoading, setIsLoading] = useState(true);

  //Name appearance animation, after that move to the top
  useEffect(() => {
    !props.loading
      ? setTimeout(() => {
          setIsLoading(false);
        }, 2000)
      : setIsLoading(true);
  }, [props.loading]);
  return (
    <>
      <div
        className={props.loading ? styles.loaderWrapper : styles.loadedWrapperr}
        id={isLoading ? "" : styles.loadedWrapper}
      >
        <div
          id={styles.rapolas}
          className={props.loading ? styles.container : styles.loadedContainer}
        >
          <div
            className={styles.loaderR}
            id={props.loading ? "" : styles.hidden}
          >
            M
          </div>
          <div id={props.loading ? styles.hidden : ""}>Marius</div>
        </div>
        <div
          className={props.loading ? styles.container : styles.loadedContainer}
        >
          <div
            className={styles.loaderS}
            id={props.loading ? "" : styles.hidden}
          >
            K
          </div>
          <div id={props.loading ? styles.hidden : ""}>Kavolis</div>
        </div>
      </div>
    </>
  );
}

import './App.scss';
import React, { useState, useEffect } from "react";
import Header from './components/header/header';
import About from './components/about/about';
import ProductsList from './components/productsList/productsList';
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import ProductDescription from './components/productDesctiption/productDescription';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import Footer from './components/footer/footer';
import Loader from "./components/loader/loader.tsx";
import Cart from './components/cart/cart.jsx';
import CartCheckout from './components/cart/cartCheckout/cartCheckout';
import OrderConfirmation from './components/orderConfirmation/orderConfirmation.jsx';
import ProductsInMainPage from './components/productsList/productsInMainPage.jsx';
import LegalDocument from './components/legal/legal.jsx';
import { Helmet } from 'react-helmet';



function App() {

  const [itemsInCartCount, setItemsInCartCount] = useState(localStorage.getItem("cart")
    ? (JSON.parse(localStorage.getItem("cart")).totalProducts)
    : 0)
  const updateCartCount = async (count) => {
    setItemsInCartCount(localStorage.getItem("cart")
      ? (JSON.parse(localStorage.getItem("cart")).totalProducts)
      : count)
  }
  const [loading, setLoading] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const updateCart = async () => {
    const productsInCart = []

    let cart = JSON.parse(localStorage.getItem("cart"))
    if (cart && cart.products) {
      cart.products.forEach(element => {
        productsInCart.push(element.id)
      });
    }
    let request = {
      ids: productsInCart
    }
    const response = await fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/products/cart", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(request)
    })
    const data = await response.text();
    localStorage.setItem("cart", data)
    await updateCartCount(data.totalProducts)
  }

  const initialFetch = async () => {
    setLoading(true);
    setIsLoading(true);
    document.body.style.overflow = 'hidden';

    await updateCart();

    // setTimeout(() => {
    setLoading(false);
    // }, 1500);

    setTimeout(() => {
      document.body.style.overflow = 'unset';
      setIsLoading(false);
    }, 3000);
  }

  useEffect(() => {
    initialFetch()
  }, []);

  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      setTimeout(() => {

        el.scrollIntoView({ behavior: "smooth" });
      }, 100)
    }
  }, [targetId]);

  return (

    <>
      <Helmet>
        <title>{"Marius Kavolis ART"}</title>
        <meta name="description" content={"Marius Kavolis original art and merchandise"} />
        <meta property="og:title" content={"Marius Kavolis ART"} />
        <meta property="og:description" content={"Marius Kavolis original art and merchandise"} />
        <meta property="og:image" content={"https://kavolis.com/marius-kavolis-portrait.jpg"} />
        <meta property="og:url" content={"https://www.kavolis.com/"} />
        <meta property="og:type" content="website" />
      </Helmet>
      {isloading ? (
        <div className="loader">
          <Loader loading={loading} />
        </div>
      ) : (<></>)}
      <div className={`App`}>

        <Header itemsInCartCount={itemsInCartCount} />
        <div className="container">

          <div className="pageLayout">
            <Routes>
              <Route path="/" element={<>
                <About />
                <ProductsInMainPage />
              </>} />
              <Route exact path="/paintings" >
                <Route path=":paintingId" element={<><ProductDescription updateCartCount={updateCartCount} />            <ScrollToTop />
                </>}></Route>
                <Route path="*" element={<Navigate to="/" />} />
              </Route>

              <Route exact path="/legal" >
                <Route path=':legalDocument' element={<><LegalDocument /> <ScrollToTop /></>} />
              </Route>
              {/* <Route exact path='/shipping-policy' component={<><LegalDocument file="./documents/shipping-policy.md" /></>} />
              <Route path='/returns-policy' exact element={<><LegalDocument file="./documents/return-policy.md" /></>} />
              <Route exact path='/privacy-policy' element={<><LegalDocument file="./documents/privacy-policy.md" /></>} />
              <Route exact path='/terms-of-service' element={<><LegalDocument file="./documents/terms-of-service.md" /></>} ></Route> */}

              <Route path="/cart" element={<><Cart updateCartCount={updateCartCount} /> <ScrollToTop /> </>}>
              </Route>
              <Route path='/checkout' element={<><CartCheckout /></>}></Route>
              <Route path='/thank-you' element={<><OrderConfirmation updateCartCount={updateCartCount} /></>} />


              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            {/* <ProductDescription imgLocation={product.imgLocation} price={product.price} productName={product.label} /> */}
            {/* <div className="blank"></div> */}
          </div>
        </div>
        <Footer />
      </div>

    </>
  );
}

export default App;

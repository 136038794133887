import React, { useState, useEffect } from "react";
import styles from "./productsList.module.scss"
import Product from "../product/product";


export default function ProductsList(props) {

    const [paintingsData, setPaintingsData] = useState([]);
    const [data, setData] = useState([props.soldPaintings ? paintingsData.filter(painting => painting.sold ? painting : "") : paintingsData.filter(painting => painting.sold === false ? painting : "")])
    const [sortType, setSortType] = useState('creationDate');
    const [productsLimit, setProductsLimit] = useState(props.limit ? props.limit : 12)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            fetchData()
        }

        return () => mounted = false;
    }, [])


    const wait = (delay) => (new Promise((resolve) => setTimeout(resolve, delay)));

    const fetchData = async (tries = 3) => {
        if (!props.disableLoader) { setLoading(true) };
        await fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/products", {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(async (response) => {
            if (response.ok) {
                const data = await response.json();
                setPaintingsData(data)
                setData(props.soldPaintings ? data.filter(painting => painting.sold ? painting : "") : data.filter(painting => painting.sold === false ? painting : ""))
                if (!props.disableLoader) { setLoading(false) };
            }
        }).catch((error) => {
            if (tries < 1) {
                throw error;
            }
            else {
                const delay = Math.floor(Math.random() * 1000);
                wait(delay).then(() => fetchData(tries - 1));
            }
        })

    }


    // const passedData = props.soldPaintings ? paintingsData.filter(painting => painting.sold) : paintingsData
    useEffect(() => {


        const sortProducts = type => {
            const types = {
                price2l: "price",
                pricel2: "price",
                date12: "creationDate",
                date2l: "creationDate"
            };
            const sortProperty = types[type];
            const requiredData = props.soldPaintings ? paintingsData.filter(painting => painting.sold) : paintingsData.filter(painting => !painting.sold)

            const sorted = [...requiredData].sort((a, b) => {
                if (sortProperty === "creationDate") {
                    const dateA = new Date(a.creationDate);
                    const dateB = new Date(b.creationDate);
                    return type === "date12" ? dateA - dateB : dateB - dateA;
                }
                else if (sortProperty === "price") {
                    return type === "pricel2" ? a.price - b.price : b.price - a.price;
                }
            })
            setData(sorted)
        }
        sortProducts(sortType)

    }, [sortType]);

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add(styles.show)
            } else {
                entry.target.classList.remove(styles.show)
            }
        })
    })

    let hiddenElements = document.querySelectorAll('.hidden');
    hiddenElements.forEach((element) => observer.observe(element))

    useEffect(() => {
        hiddenElements = document.querySelectorAll('.hidden');
        hiddenElements.forEach((element) => observer.observe(element))
    }, [hiddenElements])


    return (
        <>
            {loading ? <div className={styles.loader}>

            </div> :
                data.length < 1
                    ? <h2 className={styles.label}>There are currently no items available.</h2>
                    :
                    <div className={styles.pageLayout} id="paintingsList">

                        <h2 className={styles.label}>{props.title}</h2>
                        <div className={styles.productsFilter}>
                            Filter:
                            <select key={"products-sorting"} className={styles.selectBox} name="products-sorting" onChange={(e) => setSortType(e.target.value)}>
                                <option key={"relevance"}>Relevance</option>
                                <option key={"low-high"} value="pricel2">Price: Low - High</option>
                                <option key={"high-low"} value="price2l">Price: High - Low</option>
                                <option key={"new-old"} value="date12">Date: Newest - Oldest</option>
                                <option key={"old-new"} value="date2l">Date: Oldest - Newest</option>
                            </select>
                        </div>
                        <div className={styles.card}>
                            {data.slice(0, productsLimit).toReversed().map((element) => (
                                <>
                                    <div key={element.label} className={`${styles.block} ${styles.hidden} hidden `}>
                                        <Product product={element} />
                                    </div>
                                </>
                            ))}


                        </div>
                        {productsLimit < data.length && props.allowedToLoadMore === "true" ?
                            <div className={styles.loadMore} onClick={e => (setProductsLimit(productsLimit + 6))}>
                                <div className={styles.loadMoreButton}>Load more paintings</div></div>
                            :
                            ""
                        }
                    </div>}
        </>
    )
}
import React from "react";
import styles from "./header.module.scss"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from "react-scroll";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

export default function Header(props) {

    const path = useLocation().pathname;
    const location = path.split("/")[1];
    const navigate = useNavigate()

    // const ViewOne = () => {
    //     const navigate = useNavigate(); // From react-router v6
    //     return (
    //         <p
    //             onClick={() => {
    //                 navigate("/", { state: { targetId: "emailForm" } });
    //             }}
    //         >
    //             Link to section two, in view two
    //         </p>
    //     );
    // }

    return (
        <>
            <div className={styles.headerLayout}>
                <div className={styles.header}>
                    {location === "" ?
                        <>
                            <div className={styles.logo}>
                                <Link to={"contact"} spy={true} smooth={true} offset={-110} duration={500}>
                                    <h1>Kavolis</h1>
                                </Link>
                            </div>
                            <div className={styles.navigation}>
                                <Link className={styles.navigationItem} to={"about"} spy={true} smooth={true} offset={-100} duration={500}>
                                    <span>About</span>
                                </Link>
                                <Link className={styles.navigationItem} to={"paintingsList"} spy={true} smooth={true} offset={-110} duration={500}>
                                    <span>Shop</span>
                                </Link>
                                {/* <Link className={styles.navigationItem} to={"contact"} spy={true} smooth={true} offset={-110} duration={500}>
                                    <span>Contact me</span>
                                </Link> */}
                            </div>
                        </>
                        :
                        <>
                            <div className={styles.logo} onClick={() => { navigate("/", { state: { targetId: "contact" } }) }}>
                                <span>
                                    <h1>Kavolis</h1>
                                </span>
                            </div>
                            <div className={styles.navigation}>
                                {/* <Link className={styles.navigationItem} to={"about"} spy={true} smooth={true} offset={-100} duration={500}> */}
                                <span className={styles.navigationItem} onClick={() => { navigate("/", { state: { targetId: "" } }) }}>About</span>
                                {/* </Link> */}
                                {/* <Link className={styles.navigationItem} to={"paintingsList"} spy={true} smooth={true} offset={-110} duration={500}> */}
                                <span className={styles.navigationItem} onClick={() => { navigate("/", { state: { targetId: "paintingsList" } }) }}>Shop</span>
                                {/* </Link> */}
                                {/* <Link className={styles.navigationItem} to="/#emailForm" spy={true} smooth={true} offset={-110} duration={500}> */}
                                {/* <span className={styles.navigationItem} onClick={() => { navigate("/", { state: { targetId: "contact" } }) }}>Contact me</span> */}
                                {/* </Link>     */}
                            </div>
                        </>
                    }
                    <NavLink to="/cart">
                        <div className={styles.cart}>
                            <div className={styles.cartBackground}>
                                <img alt="cart-background" src="../../yellow-splash.svg" />
                            </div>
                            <div className={styles.cartIcon}>

                                <ShoppingCartIcon color="action" sx={{ fontSize: { xs: 22, sm: 24, md: 28, lg: 36 } }} />
                            </div>

                            <div className={`${styles.itemsInCart} ${props.itemsInCartCount ? "" : styles.emptyCart}`}>{props.itemsInCartCount}</div>

                        </div>
                    </NavLink>
                </div>
            </div>
        </>
    )
}
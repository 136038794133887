import React, { useState, useEffect } from "react";
import styles from "./orderConfirmation.module.scss"
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from "react-scroll";
import { width } from "@mui/system";
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import { Navigate, redirect, useParams, useSearchParams } from "react-router-dom";
import paintingsData from "../../paintingsData";
import ProductsList from "../productsList/productsList";
import Modal from "../modal/modal";
import { stripe } from '@stripe/react-stripe-js';

import ImgModal from "../modal/imgModal";

export default function OrderConfirmation(props) {

    const urlParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        updateCart();
    }, [])

    // (async () => {
    //     const dataParam = urlParams.get('stripe');
    //     if(dataParam){
    //         const {paymentIntent} = await stripe.retrievePaymentIntent(clientSecret);
    //         if (paymentIntent && paymentIntent.status === 'succeeded') {
    //           // Handle successful payment here
    //         } else {
    //           // Handle unsuccessful, processing, or canceled payments and API errors here
    //         }
    //     }

    //   })();


    const updateCart = async () => {
        const productsInCart = []
        const dataParam = urlParams.get('data');
        const datatoPass = { data: dataParam }
        // if (dataParam) {
        //     await fetch("https://v091nrrvg2.execute-api.eu-central-1.amazonaws.com/prod/order/complete", {
        //         method: 'put',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Accept': 'application/json'
        //         },
        //         body: JSON.stringify(datatoPass)
        //     })
        // }
        let cart = JSON.parse(localStorage.getItem("cart"))
        if (cart && cart.products) {
            cart.products.forEach(element => {
                productsInCart.push(element.id)
            });
        }
        let request = {
            ids: productsInCart
        }

        localStorage.setItem("cart", JSON.stringify(
            {
                totalProducts: 0,
                totalPrice: 0.00,
                products: []
            }
        ))
        await props.updateCartCount(0)
    }

    return (
        <>
            <div className={`${styles.pageLayout} `}>
                <div id="fullpage" onclick="this.style.display='none';"></div>
                <div className={`${styles.mainPage} gallery`}>
                    <div className={styles.title}>Thank you for your purchase!</div>
                    <div className={styles.message}>I'll contact you in 2 business days with more details</div>
                    <div className={styles.signature}> <img src="./signature.svg" /></div>

                </div>
                <ProductsList limit="4" title="Available paintings:" />
            </div>
        </>
    )
}